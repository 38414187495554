import Service from '../Service'
import { LandingV4Instance } from './Instance'

/**
 * @class
 */
export class custionarioEbr extends Service {
    constructor(creditApplicationId) {
        super()
        this._creditApplicationId = `${creditApplicationId}`
        this._resource = ''
        this._specificationResource = ''
        this._personPay = ''
        this._specificationPersonPay = ''
        this._otherIncome = ''
        this._frecuenciIncome = ''
        this._mount = ''
        this._personalPld = ''
        this._specificationPersonalPld = ''
        this._familiarPld = ''
        this._specificationFamiliarPld = ''
        this.file = null
    }

    get data() {
        const formData = new FormData();
        formData.append('creditApplicationId', `${this._creditApplicationId}`)
        formData.append('resource', `${this._resource}`)
        formData.append('specificationResource', `${this._specificationResource}`)
        formData.append('personPay', `${this._personPay}`)
        formData.append('specificationPersonPay', `${this._specificationPersonPay}`)
        formData.append('otherIncome', `${this._otherIncome}`)
        formData.append('frecuenciIncome', `${this._frecuenciIncome}`)
        formData.append('mount', `${this._mount}`)
        formData.append('personalPld', `${this._personalPld}`)
        formData.append('specificationPersonalPld', `${this._specificationPersonalPld}`)
        formData.append('familiarPld', `${this._familiarPld}`)
        formData.append('specificationFamiliarPld', `${this._specificationFamiliarPld}`)
        formData.append('file', this._file)

        return formData
    }


    /**
     * @param  {Number} idEncuesta
     */
    setData(obj) {
        this._resource = obj.resource
        this._specificationResource = obj.specificationResource
        this._personPay = obj.personPay
        this._specificationPersonPay = obj.specificationPersonPay
        this._otherIncome = obj.otherIncome
        this._frecuenciIncome = obj.frecuenciIncome
        this._mount = obj.mount
        this._personalPld = obj.personalPld
        this._specificationPersonalPld = obj.specificationPersonalPld
        this._familiarPld = obj.familiarPld
        this._specificationFamiliarPld = obj.specificationFamiliarPld
        this._file = obj.doc
    }


    /**
     * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
     */
    send() {
        const self = this
        return this._requestWrapper(LandingV4Instance({
            method: 'POST',
            url: '/verifications/create-ebrmedio',
            data: self.data
        }))
    }
}
/**
* @property {String} landingApplicationId
*/

/**
* @class
* @param  {String} landingApplicationId
*/

export class CuestionarioEbrAlto extends Service {
    constructor(landingApplicationId) {
        super()
        this._landingApplicationId = `${landingApplicationId}`
        this._file = ''
    }

    /**
      * @param {String} file   
      */
    setFile(file) {
        this._file = file
    }

    get dataPost() {
        const formData = new FormData();
        formData.append('ProofOfAddress', this._file)
        formData.append('CreditApplicationId', this._landingApplicationId)
        return formData
    }

    /**
     * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
     */
    send() {
        const self = this
        return this._requestWrapper(LandingV4Instance({
            method: 'POST',
            url: '/verifications/create-ebralto',
            data: self.dataPost,
        }))
    }
}

