
import React from "react"
import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';

import Cuestionario from '../components/pages/CuestionarioEbr'


const CuestionarioEbr = ({ pageContext }) => (
  <LayoutsComponent
    broker={pageContext.broker}
    layout={pageContext.layout}
    companyName={pageContext.company}
    render={() => (
      <>
        <SEO title={pageContext.title} />
        <Cuestionario companyName={pageContext.company} pageContext={pageContext} />
      </>
    )}>

  </LayoutsComponent>
)

export default CuestionarioEbr
